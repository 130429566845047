<template>
  <div class="content content-with-sidebar page-with-light-sidebar">
    <admin-sidebar v-model="currentTab" :tabs="tabs" />
    <component v-if="currentTab" :is="currentTab.component"></component>
  </div>
</template>

<script>
const AdminSidebar = () => import('@/components/admin/Sidebar.vue');

export default {
  name: 'Admin',
  components: {
    AdminSidebar,
  },
  data() {
    return {
      currentTab: null,
      tabs: [
        {
          icon: 'access_time',
          text: 'Activities',
          component: () => import('@/views/admin/Activities.vue'),
        },
        {
          icon: 'people',
          text: 'Accounts',
          component: () => import('@/views/admin/Accounts.vue'),
        },
        {
          icon: 'rule',
          text: 'Permissions',
          component: () => import('@/views/admin/Permissions.vue'),
        },
        {
          icon: 'code',
          text: 'Feeds',
          component: () => import('@/views/admin/Feeds.vue'),
        },
        {
          icon: 'business',
          text: 'Mandants',
          component: () => import('@/views/admin/Mandants.vue'),
        },
      ],
    };
  },
  created() {
    [this.currentTab] = this.tabs;
  },
};
</script>

<style lang="scss" scoped>
</style>
